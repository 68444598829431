import React, { Component } from 'react';
import Particles from 'react-particles-js';
import { Grid, Col, Row } from 'react-bootstrap';
import Card from "../Card/Card";
import './App.css';

// ParticlesJS Config.
var particlesOptions = {
  particles: {
    number: {
      value: 20,
      density: {
        enable: true,
        value_area: 400
      }
    }
  },
  shape: {
    type: "circle",
    stroke: {
      width: 6,
      color: "#000000"
    },
    polygon: {
      nb_sides: 5
    },
  },
  retina_detect: true
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: document.body.offsetHeight,
      width: document.body.offsetWidth
    };
    this.updateDimensions = this.updateDimensions.bind(this);
  }
  updateDimensions() {
    let width = document.body.offsetWidth;
    let height = document.body.offsetHeight;
    let windowHeight = window.innerHeight;
    height = height < windowHeight ? windowHeight : height;
    this.setState({height, width});
  }
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }
  componentWillUnmount() {
      window.removeEventListener("resize", this.updateDimensions);
  }
  render() {
    const {height, width} = this.state;
    return (
      <div>
        <Particles className="particles"
          params={particlesOptions}
          height={height}
          width={width}
        />
        <Grid>
          <Row className="show-grid">
            <Col xs={12}>
              <h1 className="cvss-title">CAV Support Services</h1>
              <h4 className="cvss-subtitle">Connected and Automated Vehicle Support Services</h4>
            </Col>
          </Row>
          <Row>
            <Card
              src="images/DSRC.png"
              label="Equipment Configuration"
              description="Help connecting and managing connected vehicle equipment."
              link="https://cvcs.samanage.com/"
            />
            <Card
              src="images/Equipment_Loan_Program.png"
              label="Equipment Loan Program"
              description="Request roadside equipment and on-board equipment to support a CV deployment."
              link="https://cvcs.samanage.com/"
            />
            <Card
              src="images/HelpDesk.png"
              label="Help Desk"
              description="Have a question? Submit a ticket and we'll answer it!"
              link="https://cvcs.samanage.com/"
            />
            <Card
              src="images/In-Facility_Testing.png"
              label="In-Facility Testing"
              description="Request space or time to test equipment."
              link="https://cvcs.samanage.com/"
            />
            <Card
              src="images/J12753_MAP.png"
              label="MAP Tool"
              description="Create MAP files containing Intersection Situation Data."
              link="https://webapp2.connectedvcs.com/"
            />
            <Card
              src="images/SCMS_POC.png"
              label="Security Certificate Management System"
              description="Request information about SCMS options."
              link="https://cvcs.samanage.com/"
            />
            <Card
              src="images/StarterKit.png"
              label="Starter Kit"
              description="Want to try CV technologies but not sure where to start?  Request a starter kit to try on your controllers and fleet vehicles."
              link="https://cvcs.samanage.com/"
            />
            <Card
              src="images/V-2-I_Suport.png"
              label="V2X Hub"
              description="Learn more about V2X Hub, an open source CAV message handler."
              link="https://cvcs.samanage.com/"
            />
          </Row>
        </Grid>
      </div>
    );
  }
}




export default App;
