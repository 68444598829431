import React from "react";
import { Col } from 'react-bootstrap';
import './Card.css';

export default ({src, label, description, link}) => (
    <Col xs={12} md={4} lg={3}>
        <a href={link} title={label}>
            <div className="cvss-card">
                <img src={src} alt="" />
                <h2>{label}</h2>
                <p>{description}</p>
            </div>
        </a>
    </Col>
)